/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/

.in-line-div {
    display: inline-block;
    margin: 10% 0 10% 0;
    width: 800px;
    box-shadow: 1px 1px 10px 1px #dadada;
}

.in-line-container {
    text-align: center;
}

.sized-container-div {
    margin: auto;
    margin-top: 5%;
    height: 476px;
    width: 600px;
    border: 1px solid #dadada;
}

.full-window-div {
    height: 100%;
}

.light-box-container {
    background-color: #fdfdf9;
    height: 100%;
}

.lb-view-file-btn {
    background-color: #1473e6;
    border: 1px #404040;
    border-radius: 5px;
    color: #ededed;
    cursor: pointer;
    font-family: "Arial";
    margin: 100px 0 0 80px;
    padding: 10px;
    width: 120px;
}

.lb-view-file-btn:hover {
    background-color: #0969df;
    color: white;
}

.file-input {
    display: none;
}

.file-picker {
    font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
    padding: 10px;
    border-radius: 5px;
    border: 1px #404040;
    cursor: pointer;
    color: #ededed;
    display: inline-block;
    background-color: #404040;
    box-shadow: 12px 5px 30px -12px rgba(0, 0, 0, 0.58);
}

.file-picker:hover {
    color: white;
    background-color: #252525;
}
